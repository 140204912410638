import React, { useMemo } from 'react'
import Slider from 'react-slick'
import { FormattedMessage, useIntl } from 'react-intl'
import NjSelect from './nj-select'
import { Link } from './link'


const settings = {
  dots: false,
  infinite: false,
  arrows: false,
  swipe: true,
  slidesToShow: 5,
  responsive: [
    {
      breakpoint: 768,
      settings: {
        slidesToShow: 4,
        centerMode: true,
        centerPadding: '0',
      }
    },
    {
      breakpoint: 576,
      settings: {
        slidesToShow: 2,
        centerMode: true,
        centerPadding: '24px',
      }
    }
  ]
};

const Filters = ({ currentFilter = 'all', filters, topics, topic, setTopic, className }) => {
  const intl = useIntl();
  const topicList = useMemo(() => {
    if (topics) {
      const memoTopics = topics.map(item => ({ label: item.taxonomyCategoryName, value: item.taxonomyCategoryId }));
      memoTopics.sort((a, b) => a.label < b.label ? -1 : 1);
      return [{ label: intl.formatMessage({ id: "clear-filter" }), value: '' }].concat(memoTopics);
    }
    return [];
  }, [topics, intl]);
  return (
    <div className={`filters pb-5${className ? ` ${className}`:''}`}>
      <span id="filters" className="scroll-anchor"></span>
      <div className="container">
        {filters.length ? (
          <>
            <div className="row">
              <div className="col-12">
                <h4 className="font-weight-light"><FormattedMessage id="filter-by" />:</h4>
              </div>
            </div>
            <div className="row">
              <div className="col-12 col-xl-8 d-flex pt-4">
                <Slider {...settings} className="filters__slider w-100">
                  {filters.map(filter => (
                    <Link to={filter.path} key={filter.value}
                      className={`filters__filter d-inline-flex flex-column align-items-center w-100${currentFilter === filter.value ? ' filters__filter--active' : ''}`} >
                      <i className={`nj-icon nj-icon--blue-corporate nj-icon-${filter.icon} ${currentFilter === filter.value ? 'nj-icon--circle' : 'nj-icon--border'}`}></i>
                      <div className={`filters__name text-center mt-1${currentFilter === filter.value ? ' text-blue-corporate' : ''}`}>
                        {filter.name}
                      </div>
                    </Link>
                  ))}
                </Slider>
              </div>
              {topicList && topicList.length > 1 && setTopic ? (
                <div className="col-8 offset-2 col-md-6 offset-md-0 col-lg-4 col-xl-4 pt-3 px-xl-6 pt-xl-5">
                  <NjSelect id="topics-select" label={`${intl.formatMessage({ id: "filter-by" })}:`}
                    options={topicList} option={topic}
                    setOption={setTopic} placeHolder={intl.formatMessage({ id: "topic" })} />
                </div>
              ) : null}
            </div>
          </>
        ) : (
          <div className="row">
            {topicList && topicList.length > 1 && setTopic ? (
              <div className="col-8 col-md-6 col-lg-4">
                <NjSelect id="topics-select" label={`${intl.formatMessage({ id: "filter-by" })}:`}
                  options={topicList} option={topic}
                  setOption={setTopic} placeHolder={intl.formatMessage({ id: "topic" })} />
              </div>
            ) : null}
          </div>
        )}
      </div>
    </div>
  );
}

export default Filters;