import React from 'react'
import { Link } from '../link'
import withPreview from '../../utility/with-preview'
import pageUrl from '../../utility/page-url'


const TileWrapper = ({ data: { linkedContent, linkedDocument, externalLink }, className, children }) => {
  if (linkedContent) {
    return (
      <Link to={`/${pageUrl(linkedContent)}`} className={`tile-list__tile${className ? ` ${className}`: ''}`}>
        {children}
      </Link>
    );
  } else if (linkedDocument?.node?.publicURL && linkedDocument?.content?.linkedDocumentEn?.node?.publicURL) {
    return (
      <div
        className={`tile-list__tile${className ? ` ${className}`: ''}`}>
        {children}
      </div>
    );
  } else {
    const link = (linkedDocument && linkedDocument.node && linkedDocument.node.publicURL) || 
      (externalLink && externalLink.value);
    return (
      <a href={link} target="_blank" rel="noopener noreferrer" 
        className={`tile-list__tile${className ? ` ${className}`: ''}`}>
        {children}
      </a>
    )
  }
}

export default withPreview(TileWrapper);