import React, { useState, useCallback } from 'react'
import KeyboardArrowDownIcon from '../images/icons/material/keyboard_arrow_down.svg'


const NjSelect = ({ id = 'njSelect', name = '', warning, label, options, option, setOption, placeHolder = 'Seleziona', disabled }) => {
  const [focus, setFocus] = useState(false);
  const onFocus = useCallback(() => setFocus(true), []);
  const onBlur = useCallback(() => setFocus(false), []);
  const onChange = useCallback((e) => setOption(e.target.value), [setOption]);

  return (
    <div className={`nj-form-group${option ? ' is-filled' : ''}${focus ? ' is-focused' : ''}${warning ? ' has-warning': ''}`}>
      <select id={id} className="nj-form-control" name={name} 
        value={option} onFocus={onFocus} onBlur={onBlur} onChange={onChange} disabled={disabled}>
        <option value="" disabled={true}>{placeHolder}</option>
        {options.map(option => (
          <option value={option.value} key={option.value}>
            {option.label}
          </option>
        ))}
      </select>
      <label htmlFor={id} className="nj-label-static">{label}</label>
      <KeyboardArrowDownIcon className="material-svg" />
    </div>
  );
}

export default NjSelect;