import React from 'react'


const TileCta = ({ data }) => {
  const { content } = data.cardTitle;
  const { linkedDocument } = data;

  return (
    <>
      {content.btnText?.content && linkedDocument?.node?.publicURL 
         && linkedDocument?.content?.linkedDocumentEn?.node?.publicURL 
         && linkedDocument?.content?.btnTextEn?.value ?  (
         <div className="d-flex justify-content-between w-100">
           <a href={linkedDocument.node.publicURL} target="_blank" rel="noopener noreferrer"
              className={`nj-btn position-relative ${(content.btnText.content && content.btnText.content.isWhite) ? 'nj-btn--light' : 'nj-btn--primary'} mt-5`}>
              {content.btnText.value}
            </a>
            <a href={linkedDocument.content.linkedDocumentEn.node.publicURL} target="_blank" rel="noopener noreferrer" 
              className={`nj-btn position-relative ${(content.btnText.content && content.btnText.content.isWhite) ? 'nj-btn--light' : 'nj-btn--primary'} mt-5`}>
              {linkedDocument.content.btnTextEn.value}
            </a>
         </div>
      ) : (
        content.btnText?.content &&
          <button className={`nj-btn position-relative ${(content.btnText.content && content.btnText.content.isWhite) ? 'nj-btn--light' : 'nj-btn--primary'} mt-5`}>
            {content.btnText.value}
          </button>
      )}
    </>
  );
}

export default TileCta;
