import React, { useMemo } from 'react'
import { GatsbyImage as Img, getImage, withArtDirection } from 'gatsby-plugin-image'
import TileWrapper from './tile-wrapper'
import TileCta from './tile-cta'


const TileDouble = ({ data }) => {
  const { value: title, content } = data.cardTitle;
  const { cardImage, largeImage } = content || {};

  const sources = useMemo(() => {
    return cardImage ? withArtDirection(getImage(largeImage.node), [
      {
        media: "(max-width: 576px)",
        image: getImage(cardImage.node),
      },
    ]): getImage(largeImage.node);
  }, [cardImage, largeImage]);

  return (
    <TileWrapper data={data} className="tile-list__tile--double p-5 d-flex flex-column">
      {(content.largeImage && content.largeImage.node) && (
        <Img objectFit="cover" image={sources} alt={content.largeImage.description || ''} className="tile-list__img" />
      )}
      <h3 className="tile-list__title position-relative text-white font-weight-bold"
        dangerouslySetInnerHTML={{ __html: title }}></h3>
     <TileCta data={data}/>
    </TileWrapper>
  );
}

export default TileDouble;