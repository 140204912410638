import React from 'react'
import { GatsbyImage as Img, getImage } from 'gatsby-plugin-image'
import TileWrapper from './tile-wrapper'


const TileCenter = ({ data }) => {
  const { value: title, content } = data.cardTitle;
  return (
    <TileWrapper data={data}
      className="tile-list__tile--center p-5 d-flex flex-column text-center justify-content-center">
      {(content.cardImage && content.cardImage.node) && (
        <Img  objectFit="cover" image={getImage(content.cardImage.node)}
          alt={content.cardImage.description || ''} className="tile-list__img" />
      )}
      <h3 className="tile-list__title position-relative text-white font-weight-bold"
        dangerouslySetInnerHTML={{ __html: title }}></h3>
    </TileWrapper>
  );
}

export default TileCenter;