import React, { useCallback, useState } from 'react'
import { GatsbyImage as Img, getImage } from 'gatsby-plugin-image'
import VideoModal from '../video-modal'
import TileWrapper from './tile-wrapper'


const TileVideo = ({ data }) => {
  const { value: title, content } = data.cardTitle;
  const [visibleVideo, setVisibleVideo] = useState(false);
  const showVideo = useCallback((e) => {
    e.preventDefault();
    e.stopPropagation();
    setVisibleVideo(true);
  }, []);
  const hideVideo = useCallback(() => setVisibleVideo(false), []);
  return (
    <TileWrapper data={data} className="p-5 d-flex flex-column tile-list__tile--video">
      {(content.cardImage && content.cardImage.node) && (
        <Img  objectFit="cover" image={getImage(content.cardImage.node)}
          alt={content.cardImage.description || ''} className="tile-list__img" />
      )}
      {content.linkVideo && content.linkVideo.value && (
        <button className="tile-list__btn-video" onClick={showVideo}>
          <i className="nj-icon nj-icon-arrowright nj-icon--border nj-icon--white tile-list__video position-relative mb-3">
          </i>
        </button>
      )}
      <h3 className="tile-list__title position-relative text-white"
        dangerouslySetInnerHTML={{ __html: title }}></h3>
      <VideoModal show={visibleVideo} handleClose={hideVideo} video={content.linkVideo.value} />
    </TileWrapper>
  );
}

export default TileVideo;