import React, { useState, useCallback } from 'react'
import { GatsbyImage as Img, getImage } from 'gatsby-plugin-image'
import Slider from "react-slick"
import { Link } from "./link"
import withPreview from '../utility/with-preview'
import pageUrl from '../utility/page-url'


const settings = {
  dots: false,
  infinite: true,
  autoplay: true,
  autoplaySpeed: 5000,
  arrows: false,
  swipe: true,
  slidesToShow: 1
};


const SlideWrapper = ({ data: { linkedContent, linkedDocument, externalLink }, children }) => {
  if (linkedContent) {
    return (
      <Link to={`/${pageUrl(linkedContent)}`}
        className="carousel-full__slide d-flex flex-column justify-content-center">
        {children}
      </Link>
    );
  } else {
    const link = (linkedDocument && linkedDocument.node && linkedDocument.node.publicURL) ||
      (externalLink && externalLink.value);
    return (
      <a href={link} target="_blank" rel="noopener noreferrer"
        className="carousel-full__slide d-flex flex-column justify-content-center">
        {children}
      </a>
    )
  }
}

const Slide = ({ data, intro, slides, gotoSlide, index }) => {
  const { cardTitle: { value, content } } = data;
  return (
    <SlideWrapper data={data}>
      <Img image={getImage(content.largeImage.node)} alt={content.largeImage.node.description || ''}
        className="carousel-full__img" />
      <div className="container">
        <div className="row">
          <div className="col-12">
            <div className="carousel-full__content">
              {intro ? (
                <h2 className="carousel-full__intro mb-2">{intro}</h2>
              ) : null}
              <h2 className="carousel-full__title mb-2"
                dangerouslySetInnerHTML={{ __html: value }}></h2>
              {content.largeImage.content ? (
                <div className="carousel-full__subtitle pb-4"
                  dangerouslySetInnerHTML={{ __html: content.largeImage.content.cardSubtitle.value }}></div>
              ) : null}
              <div className="d-flex mt-4">
                {content.btnText && content.btnText.value && (
                  <button className="nj-btn nj-btn--primary nj-btn--lg">{content.btnText.value}</button>
                )}
                {slides.length > 1 ? (
                  <div className="d-flex align-items-center p-2 pl-5">
                    {slides.map((_item, j) => (
                      <button className={`carousel-full__dot${index === j ? ' carousel-full__dot--active' : ''}`}
                        onClick={(e) => gotoSlide(e, j)} key={j} aria-label={`Slide ${j}`}></button>
                    ))}
                  </div>
                ): null}
              </div>
            </div>
          </div>
        </div>
      </div>
    </SlideWrapper>
  );
}

const PreviewSlide = withPreview(Slide);

const CarouselFull = ({ data: { slides }, intro }) => {
  const [slider, setSlider] = useState(null);
  const gotoSlide = useCallback((e, i) => {
    e.preventDefault();
    slider.slickGoTo(i);
  }, [slider]);

  return (
    <div className="carousel-full">
      <Slider {...settings} ref={setSlider} className="carousel-full__slider">
        {slides.map((slide, i) => <PreviewSlide data={slide} intro={intro} slides={slides}
          gotoSlide={gotoSlide} key={i} index={i} />)}
      </Slider>
    </div>
  );
}

export default CarouselFull;
