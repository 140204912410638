import React from 'react'
import SvgIcon from './svg-icon'
import TileWrapper from './tile-wrapper'
import TileCta from './tile-cta'

const TileLight = ({ data, light = false }) => {
  const { value: title, content } = data.cardTitle;
  const colorCategory = content.category.content?.colorCategory?.value[0] || '';

  return (
    <TileWrapper data={data} className={`p-5 d-flex flex-column${light ? ' tile-list__tile--light' : ''}`}>

      {content.icon && content.icon.value && (
        <SvgIcon icon={content.icon.value} 
          className={`tile-list__icon position-relative mb-5 ${light ? 'text-blue-allports': 'text-white'}`} />
      )}
      {content.category && content.category.value && (
        <div className={`${(colorCategory==='blue' || (colorCategory==='' && light)) ? 'text-blue-corporate' : 'text-white'} t-centi font-weight-bold position-relative text-uppercase`}>
          {content.category.value}
        </div>
      )}
      <h3 className={`tile-list__title position-relative ${light ? 'text-blue-corporate' : 'text-white'}`}
        dangerouslySetInnerHTML={{ __html: title }}></h3>
      
      <TileCta data={data}/>

    </TileWrapper>
  );
}

export default TileLight;