import React, { useState, useMemo, useEffect } from 'react'
import { navigate } from 'gatsby'
import { FormattedMessage } from 'react-intl'
import { Link } from './link'
import Tile from './elements/tile'
import TileDouble from './elements/tile-double'
import TileCenter from './elements/tile-center'
import TileLight from './elements/tile-light'
import TileVideo from './elements/tile-video'


const getType = cardTitle => cardTitle.content.tipologia.value[0];

const TileList = ({ tiles, rows = 2, highlightField = "highlight", currentFilter, hasLoadMore, related, topic,
  linkLabel = 'Newsroom', loadedRows }) => {
  // Inizia con min 1, max 8
  const startRows = Math.min(8, Math.max(1, parseInt(rows)));
  const currentRows = loadedRows && !isNaN(parseInt(loadedRows)) ? parseInt(loadedRows) : startRows;

  const [currentScroll, setCurrentScroll] = useState(null);
  const [loading, setLoading] = useState(false);
  const loadMore = () => setLoading(true);
  useEffect(() => {
    if (loading) {
      const timeout = setTimeout(() => {
        setCurrentScroll((document.scrollingElement || document.documentElement || document.body).scrollTop);
        const path = `${window.location.pathname}?loaded=${currentRows+startRows}`;
        navigate(path);
        setLoading(false);
      }, 500);
      return () => clearTimeout(timeout);
    }
  }, [loading, currentRows, startRows]);

  // Mantieni la posizione di scroll
  useEffect(() => {
    if (currentScroll && currentRows !== startRows) {
      (document.scrollingElement || document.documentElement || document.body).scrollTop = currentScroll;
    }
  }, [currentRows, currentScroll, startRows]);

  // Filtra in base a filtro e argomenti
  const filteredTiles = useMemo(() => {
    if (!currentFilter || currentFilter === 'all' || currentFilter === 'all_educational') {
      return topic ? tiles.filter(tile => {
        const contentTopics = tile.linkedContent ? tile.linkedContent.taxonomyCategoryBriefs : tile.taxonomyCategoryBriefs;
        return contentTopics ? !!contentTopics.find(item => `${item.taxonomyCategoryId}` === topic) : false;
      }) : tiles;
    }
    const typeFiltered = tiles.filter(tile => tile.tileType.includes(currentFilter));
    if (!topic) return typeFiltered;
    return typeFiltered.filter(tile => {
      const contentTopics = tile.linkedContent ? tile.linkedContent.taxonomyCategoryBriefs : tile.taxonomyCategoryBriefs;
      return contentTopics ? !!contentTopics.find(item => `${item.taxonomyCategoryId}` === topic) : false;
    });
  }, [tiles, currentFilter, topic]);

  // Aggiungi metadati sulla lunghezza dei tiles per tablet e desktop
  const tilesList = useMemo(() => {
    const list = filteredTiles.slice(0, currentRows * 3);
    const meta = related ? list.map(() => ({})) : list.reduce((meta, item) => {
      const effectiveIndexTb = meta.reduce((res, el) => res + (el.dTb ? 2 : 1), 0);
      const effectiveIndexDs = meta.reduce((res, el) => res + (el.dDs ? 2 : 1), 0);
      const dTb = item[highlightField] && effectiveIndexTb % 2 === 0;
      const dDs = item[highlightField] && ((effectiveIndexDs % 3 === 0) || (effectiveIndexDs % 3 === 1));
      return meta.concat([{ dTb, dDs }]);
    }, []);
    return list.map((tile, i) => ({ tile, meta: meta[i] }));
  }, [filteredTiles, currentRows, highlightField, related]);

  // Taglia le ultime righe vuote sul desktop
  const slicedTiles = useMemo(() => {
    let maxLengthDesktop = 0;
    let filledRows = 0;
    let tilesLength = 0;
    for (let i = 0; i < tilesList.length; i++) {
      maxLengthDesktop = i + 1;
      const { meta } = tilesList[i];
      tilesLength += (meta.dDs ? 2 : 1);
      if (tilesLength >= 3) {
        filledRows++;
        tilesLength -= 3;
        if (currentRows === startRows) {
          // All'inizio riempi le right esatte
          if (filledRows === currentRows){
            break;
          }
        } else {
          // Riempi una riga in più
          if (filledRows > currentRows) {
            break;
          }
        }
      }
    }  
    return tilesList.slice(0, maxLengthDesktop);
  }, [tilesList, currentRows, startRows]);
  
  const canLoadMore = !related && loadMore && (filteredTiles.length > tilesList.length || slicedTiles.length < tilesList.length);

  return (
    <div className="tile-list text-white">
      <div className="container-fluid">
        <div className={`row${related ? ' justify-content-center': ''}`}>
          {slicedTiles.map(({ tile, meta }, i) => (
            <div className={`${meta.dTb ? 'dtb ' : 'col-sm-6 '}${meta.dDs ? 'col-lg-8 dds' : 'col-lg-4'} px-0`} key={i}>
              <>
                {
                  tile[highlightField] ? <TileDouble data={tile} /> :
                    {
                      fotoCta: <Tile data={tile} />,
                      iconCta: <TileLight data={tile} />,
                      iconCtaWhite: <TileLight data={tile} light={true} />,
                      foto: <TileCenter data={tile} />,
                      video: <TileVideo data={tile} />,
                    }[getType(tile.cardTitle)]
                }
              </>
            </div>
          ))}
        </div>
        <div className="row">
          <div className="col px-0">
            {hasLoadMore ? (
              canLoadMore ? (
                <button className="tile-list__load-more text-white bg-blue-corporate w-100 py-3 t-deci" onClick={loadMore}>
                  <div className="d-flex justify-content-center align-items-center">
                    {loading ?
                      <>
                        <span><FormattedMessage id="loading" /></span>
                        <div className="nj-spinner nj-spinner--xs ml-2" role="status">
                          <span className="sr-only">Loading...</span>
                        </div>
                      </>
                      : <>
                        <span><FormattedMessage id="load-more" /></span>
                        <span className="tile-list__arrow ml-3"></span>
                      </>
                    }
                  </div>
                </button>
              ): null
            ) : (
              related ? null : (
                <Link to="/newsroom/" className="tile-list__load-link text-white bg-blue-corporate w-100 py-3 t-deci">
                  {linkLabel}
                </Link>
              )
            )}
          </div>
        </div>
      </div>
    </div >
  );
}

export default TileList;