import React, { useState, useMemo } from 'react'
import { graphql } from 'gatsby'
import TileList from './tile-list'
import CarouselFull from './carousel-full'
import Filters from './filters'


const Lanci = ({ data: { highlightNumber, rowsNumber, linkLabel }, tileList: { edges: lanci }, 
  filters = false, highlightField = "highlight", intro, currentFilter, hasLoadMore, loadedRows }) => {
  
  // Min 1, max 4
  const highlightNum = Math.min(4, Math.max(1, highlightNumber.value));

  const highlights = useMemo(() => {
    const mapped = lanci.map(edge => edge.node);
    return mapped.filter(item => item[highlightField]).slice(0, highlightNum);
  }, [lanci, highlightField, highlightNum]);
  
  const tiles = useMemo(() => {
    return lanci.filter(edge => !highlights.find(item => item.articleId === edge.node.articleId)).map(edge => edge.node);
  }, [highlights, lanci]);

  
  // console.log('tiles: ', tiles)

  const topics = useMemo(() => {
    if (!filters) return [];
    const typeFiltered = (!currentFilter || currentFilter === 'all' || currentFilter === 'all_educational') ? 
      tiles : tiles.filter(tile => tile.tileType.includes(currentFilter));
    return typeFiltered.reduce((res, item) => {
      const taxonomyCategoryBriefs = item.linkedContent ? 
        item.linkedContent.taxonomyCategoryBriefs : item.taxonomyCategoryBriefs;
      return taxonomyCategoryBriefs ? res.concat(taxonomyCategoryBriefs.filter(e => {
        return !!e && !res.find(exs => exs.taxonomyCategoryId === e.taxonomyCategoryId);
      })) : res;
    }, []);
  }, [tiles, filters, currentFilter]);
  
  const [topic, setTopic] = useState('');

  return (
    <div className="pb-5 mb-5">
      <CarouselFull data={{ slides: highlights }} intro={intro} />
      {filters ? (
        <Filters currentFilter={currentFilter} filters={filters} 
          topics={topics} topic={topic} setTopic={setTopic} className={highlights.length ? 'pt-5': ''} />
      ): null}
      <TileList tiles={tiles} rows={rowsNumber.value} highlightField={highlightField} loadedRows={loadedRows}
        hasLoadMore={hasLoadMore} currentFilter={currentFilter} topic={topic} linkLabel={linkLabel && linkLabel.value} />
    </div>
  );
}

export default Lanci;
export const lanciFragment = graphql`fragment LanciFragment on LiferayLanci{
  siteId
  articleId
  highlightNumber {
    value
  }
  rowsNumber {
    value
  }
  linkLabel {
    value
  }
}`;