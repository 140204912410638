import React from 'react'
import { graphql } from 'gatsby'
import { GatsbyImage as Img, getImage } from 'gatsby-plugin-image'
import SvgIcon from './svg-icon'
import TileWrapper from './tile-wrapper'
import TileCta from './tile-cta'

const Tile = ({ data }) => {
  const { value: title, content } = data.cardTitle;
  const colorCategory = content.category.content?.colorCategory?.value[0] || '';

  return (
    <TileWrapper data={data} className="p-5 d-flex flex-column">
      {(content.cardImage && content.cardImage.node) && (
        <Img  objectFit="cover" image={getImage(content.cardImage.node)}
          alt={content.cardImage.description || ''} className="tile-list__img" />
      )}
      {content.icon && content.icon.value && (
        <SvgIcon icon={content.icon.value} className="tile-list__icon position-relative mb-5 text-white" />
      )}
      {content.category && content.category.value && (
        <div className={`${(colorCategory==='blue' || (content.cardImage && colorCategory === ''))  ? 'text-blue-corporate' : 'text-white'} t-centi font-weight-bold position-relative text-uppercase`}>
          {content.category.value}
        </div>
      )}

      <h3 className="tile-list__title position-relative text-white"
        dangerouslySetInnerHTML={{ __html: title }}></h3>

      <TileCta data={data}/>

    </TileWrapper>
  );
}

export default Tile;
export const tileFragment = graphql`fragment TileFragment on LiferayTile{
  siteId
  articleId
  effectiveDate
  highlight
  highlightNews
  cardTitle {
    value
    content {
      largeImage {
        node {
          description
          gatsbyImageData(width: 960)
        }
        content {
          cardSubtitle {
            value
          }
        }
      }
      tipologia {
        value
      }
      cardImage {
        node {
          description
          gatsbyImageData(width: 600)
        }
      }
      category {
        value
        content {
          colorCategory {
            value
          }
        }
      }
      icon {
        value
      }
      btnText {
        value
        content {
          isWhite
        }
      }
      linkVideo {
        value
      }
    }
  }
  tileType
  linkedContent {
    __typename
    ... on LiferayPaginainterna {
      langCode
      pageTitle {
        value
        content {
          friendlyUrl{
            value
          }
        }
      }
    }
    ... on LiferayShortForm {
      langCode
      pageTitle {
        value
        content {
          friendlyUrl {
            value
          }
        }
      }
      mediaType {
        value
      }
      taxonomyCategoryBriefs {
        taxonomyCategoryId
        taxonomyCategoryName
      }
    }
    ... on LiferayLongForm {
      langCode
      pageTitle {
        value
        content {
          friendlyUrl {
            value
          }
        }
      }
      mediaType {
        value
      }
      taxonomyCategoryBriefs {
        taxonomyCategoryId
        taxonomyCategoryName
      }
    }
  }
  linkedDocument {
    content {
      linkedDocumentEn {
        node {
          publicURL
        }
      }
      btnTextEn {
        value
      }
    }
    node {
      publicURL
    }
  }
  externalLink {
    value
  }
  taxonomyCategoryBriefs {
    taxonomyCategoryId
    taxonomyCategoryName
  }
}
`