import React from 'react'
import Report from '../../images/icons/report.svg'
import Laptop from '../../images/icons/laptop.svg'
import Lens from '../../images/icons/lens.svg'
import Growth from '../../images/icons/growth.svg'

import Sport from '../../images/icons/sport.svg'
import Number1 from '../../images/icons/number-1.svg'


const SvgIcon = ({ icon, ...other }) => {
  // console.log('icon: ', icon)
  const { className, ...rest } = other;
  return {
    'svg-report': <Report {...other} />,
    'svg-laptop': <Laptop {...other} />,
    'svg-lens': <Lens {...other} />,
    'svg-growth': <Growth {...other} />,
    'sport': <Sport {...other} />,
    'number-1': <Number1 {...other} />
  }[icon] || <i className={`nj-icon nj-icon-${icon}${className ? ` ${className}`: ''}`} {...rest}></i>;
};

export default SvgIcon;